<template>
  <div
    class="relative z-admin-bar hidden overflow-x-clip whitespace-nowrap border-b border-b-grey-light-01 bg-white xl:block"
  >
    <VuepalAdminToolbar />
    <div
      class="flex items-center gap-16 border-b border-b-grey-light-01 bg-white p-6 px-16 py-16"
    >
      <VuepalLocalTasks class="mr-auto" :path-override="localTasksPath" />
      <button
        class="font-bold uppercase"
        :class="{ 'text-grey-light-01': !showGrid }"
        @click="showGrid = !showGrid"
      >
        Grid
      </button>
      <AdminBarCountrySwitcher />
      <AdminBarLanguageSwitcher />
    </div>
  </div>

  <div v-if="showGrid" class="bk-grid-overlay">
    <div class="grid-debug layout-grid h-full">
      <div v-for="n in 14" :key="n">
        {{ n }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const showGrid = useLocalStorage('admin', 'showGrid', false)

const route = useRoute()

/**
 * Because the path of the product variation does technically not exist in
 * Drupal, we would not be able to display local tasks for PDPs. This is why
 * we have to return the path to the product (not variation!) in this case.
 *
 * Example: /en-US/product/watch/aquis/aquis-gmt-date/01-798-7754-4135-07-8-24-05PEB
 * Returned path: /en-US/product/watch/aquis/aquis-gmt-date
 */
const localTasksPath = computed(() => {
  // All PDPs have a SKU route param.
  const sku = route.params.sku
  if (sku && typeof sku === 'string') {
    // Remove the SKU including the preceeding slash from the path.
    return route.path.replace('/' + sku, '')
  }

  return route.path
})
</script>

<style lang="postcss">
.bk-grid-overlay {
  @apply pointer-events-none fixed left-0 top-0 z-[9999999999] h-full w-full;

  .grid-debug {
    @apply pointer-events-none h-full;

    > div {
      @apply flex items-end justify-center bg-red-light-01/25 pb-24 text-2xl font-bold;
      @apply text-red-dark-02;

      &:first-child,
      &:last-child {
        @apply bg-blue-light-01/25;
        @apply text-blue-dark-02;
      }

      &:nth-child(6) {
        @apply bg-blue-light-01/25;
        @apply text-blue-dark-02;

        @screen md {
          @apply bg-red-light-01/25;
          @apply text-red-dark-02;
        }
      }

      &:nth-child(n + 7) {
        @apply hidden;

        @screen md {
          @apply flex;
        }
      }
    }
  }
}

.admin-toolbar-overlay {
  @apply z-admin-bar-overlay;
}
</style>
